import React from 'react';
import { Jumbotron, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';

class Login extends React.Component {

    render () {
        return (
            <Container>
                <Jumbotron>
                    <h2>Login</h2>
                    <Link to="/recipes">Recipes</Link>
                </Jumbotron>
            </Container>
        );
    }
    
}

export default Login;