import React from 'react';
import { Jumbotron, Container, Card, Col, Row, Spinner} from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchRecipes, setSortOption, setFilters } from '../../actions/recipesActions';
import { filterAndSortRecipes } from '../../setup/recipesListHelpers';
import { S3_HOST, S3_PORT } from '../../setup/constants';
import { Link } from 'react-router-dom';
import './style.css';

class Recipes extends React.Component {

    componentDidMount () {
        this.props.fetchRecipes();
    }

    renderRecipe (recipe) {
        let coverImage = (recipe.coverImageId === null) ? 'https://images.pexels.com/photos/349609/pexels-photo-349609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940' : S3_HOST + S3_PORT + '/' + recipe.coverImageId;
        let recipeUrl = "/recipes/" + recipe.id;        

        return (
            <Col key={recipe.id}>
                <Link to={recipeUrl}>
                    <Card >
                        <Card.Img variant="top" src={coverImage} />
                        <Card.Body>
                            <Card.Title>{recipe.name}</Card.Title>
                            <Card.Text>Text</Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
        );
    }

    renderRecipes () {
        if (this.props.recipes.length == 0) {
            return "";
        }

        return this.props.recipes.map(
            (section) => section.data.map((recipe) => this.renderRecipe(recipe)))
    }

    render () {
        const recipeItems = this.renderRecipes();
        let spinnerDisplay = this.props.loading ? "" : "none"; 

        return (
            <Container>
                <Jumbotron>
                    <h2>Recipes</h2>
                </Jumbotron>
                <Container fluid>
                    <Row>
                        <Col>
                            <Spinner animation="border" role="status" variant="primary" style={{marginBottom: 20, display: spinnerDisplay}}>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    <Row>
                        {recipeItems}
                    </Row>
                </Container>
            </Container>
        );
    }
}

function mapStateToProps(state, props) {
    return {
      recipes: filterAndSortRecipes(state.RecipesReducer.recipes, state.RecipesReducer.filters, state.RecipesReducer.sortOption),
      sortOption: state.RecipesReducer.sortOption,
      filters: state.RecipesReducer.filters,
      loading: state.RecipesReducer.loading
    }
}

const mapDispatchToProps = {
    fetchRecipes, setSortOption, setFilters
}

//Connect everything
export default connect(mapStateToProps, mapDispatchToProps)(Recipes);