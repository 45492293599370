import React from 'react';
import { Jumbotron, Container, Spinner, Col, Row, Image, Modal, Carousel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchRecipes } from '../../actions/recipesActions';
import { S3_HOST, S3_PORT } from '../../setup/constants';
import { MetaTags } from 'react-meta-tags';
import './style.css';

class Recipe extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.props.recipe == null) {
            this.props.fetchRecipes();
        }
    }

    renderLoading() {
        return (
            <Container className="text-center">
                <Row>
                    <Col>
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderThumbnails() {
        let imagesList = [];

        if (this.props.recipe.imageIds) {
            imagesList = this.props.recipe.imageIds.map(function (image, i) {
                let imagePath = S3_HOST + S3_PORT + '/' + image;

                return (
                    <Col key={"imageThumbnail" + i}>
                        <Image thumbnail src={imagePath} height={150} onClick={this.openModal} />
                    </Col>
                );
            }, this);
        }

        return imagesList;
    }

    renderIngredients() {
        return this.props.recipe.ingredients.map(function (ingredient, i) {
            let label = [ingredient.amount, ingredient.size, ingredient.ingredient].filter(Boolean).join(" ");

            // Code below to provide a lable like "1 package cream cheese" or "Tortilla chips"
            let firstCharRegEx = /[a-zA-Z]/;
            let firstCharIndex = label.search(firstCharRegEx);

            if (firstCharIndex == 0) {
                if (firstCharIndex != label.length - 1) {
                    label = label.charAt(0).toUpperCase() + label.substring(1).toLowerCase();
                } else {
                    label = label.charAt(0).toUpperCase();
                }
            } else if (firstCharIndex > 0) {
                label = label.toLowerCase();
            }

            return (
                <li key={"ingredient-" + i}>{label}</li>
            );
        }, this);
    }

    renderDirections() {
        return this.props.recipe.directions.map(function (d, i) {
            return <li key={"direction-" + i}>{d.direction}</li>;
        });
    }

    renderImages() {
        let imagesList = [];

        if (this.props.recipe.imageIds) {
            imagesList = this.props.recipe.imageIds.map(function (image, i) {
                let imagePath = S3_HOST + S3_PORT + '/' + image;

                return (
                    <Carousel.Item key={"carouselImages-" + i}>
                        <Image src={imagePath} fluid />
                    </Carousel.Item>
                );
            }, this);
        }

        return imagesList;
    }

    openModal() {
        this.setState({
            modalOpen: true
        });
    }

    closeModal() {
        this.setState({
            modalOpen: false
        });
    }

    renderRecipe() {
        let coverImage = (this.props.recipe.coverImageId === null) ? 'https://images.pexels.com/photos/349609/pexels-photo-349609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940' : S3_HOST + S3_PORT + '/' + this.props.recipe.coverImageId;
        let thumbnails = this.renderThumbnails();
        let images = this.renderImages();
        let ingredients = this.renderIngredients();
        let directions = this.renderDirections();
        let title = "Recipes | " + this.props.recipe.name;

        return (
            <>
                <MetaTags>
                    <title>Recipes | {this.props.recipe.name}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={coverImage} />
                </MetaTags>
                <Container>
                    <Row>
                        <Col>
                            <h2>{this.props.recipe.name}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Image rounded fluid src={coverImage} />
                        </Col>
                    </Row>
                    <Row sm={4} md={6} lg={8}>
                        {thumbnails}
                    </Row>
                    <Row>
                        <Col>
                            <h4>Ingredients:</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul>{ingredients}</ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Directions:</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ol>{directions}</ol>
                        </Col>
                    </Row>
                </Container>

                <Modal size="lg" show={this.state.modalOpen} centered aria-labelledby="contained-modal-title-vcenter">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Pictures
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Carousel controls={images.length > 1} indicators={images.length > 1}>
                            {images}
                        </Carousel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal}>Close</Button>
                    </Modal.Footer>

                </Modal>
            </>
        );
    }

    render() {

        if (this.props.recipe == null) {
            return this.renderLoading();
        } else {
            return this.renderRecipe();
        }
    }
}

function mapStateToProps(state, props) {
    return {
        recipe: state.RecipesReducer.recipes[props.match.params.id],
    }
}

const mapDispatchToProps = {
    fetchRecipes
}

//Connect everything
export default connect(mapStateToProps, mapDispatchToProps)(Recipe);