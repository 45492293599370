import { createReducer } from '@reduxjs/toolkit';
import { GET_RECIPES, GET_RECIPES_LOADED, GET_RECIPES_FAILED, ADD_RECIPE, ADD_RECIPE_COMPLETE, ADD_RECIPE_FAILED, EDIT_RECIPE, EDIT_RECIPE_COMPLETE, EDIT_RECIPE_FAILED, DELETE_RECIPE, DELETE_RECIPE_COMPLETE, DELETE_RECIPE_FAILED, SET_SORT_OPTION, SortOptions, SET_FILTERS } from '../setup/constants'

const INITIAL_STATE = {
  recipes: [],
  loading: false,
  sortOption: SortOptions.NAME_ASC,
  filters: {},
  error: '',
};

const RecipesReducer = createReducer(INITIAL_STATE, {
  GET_RECIPES: (state, action) => {
    state.loading = action.loading;
    state.error = '';
  },
  GET_RECIPES_LOADED: (state, action) => {
    state.recipes = action.recipes;
    state.loading = action.loading;    
  },
  GET_RECIPES_FAILED: (state, action) => {
      state.loading = action.loading;
      state.error = action.error;
  },
  // TODO - Add Loading
  ADD_RECIPE: (state, action) => {
    state.loading = action.loading;
    state.error = '';
  },
  ADD_RECIPE_COMPLETE: (state, action) => {
    state.recipes[action.recipe.id] = action.recipe;
    state.loading = action.loading;
  },
  ADD_RECIPE_FAILED: (state, action) => {
    state.loading = action.loading;
    state.error = action.error;
  },
  EDIT_RECIPE: (state, action) => {
    state.loading = action.loading;
    state.error = '';
  },
  EDIT_RECIPE_COMPLETE: (state, action) => {
    state.recipes[action.recipe.id] = action.recipe;
    state.loading = action.loading;
  },
  EDIT_RECIPE_FAILED: (state, action) => {
    state.loading = action.loading;
    state.error = action.error;
  },
  DELETE_RECIPE: (state, action) => {
    state.loading = action.loading;
    state.error = '';
  },
  DELETE_RECIPE_COMPLETE: (state, action) => {
    state.loading = action.loading;
  },
  DELETE_RECIPE_FAILED: (state, action) => {
    state.loading = action.loading;
    state.error = action.error;
  },
  SET_SORT_OPTION: (state, action) => {
    state.sortOption = action.option;
  },
  SET_FILTERS: (state, action) => {
    state.filters = action.filters
  }
});

export default RecipesReducer;