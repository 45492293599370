export const GET_RECIPES = "GET_RECIPES";
export const GET_RECIPES_LOADED = "GET_RECIPES_LOADED";
export const GET_RECIPES_FAILED = "GET_RECIPES_FAILED";
export const SET_SORT_OPTION = "SET_SORT_OPTION";
export const SET_FILTERS = "SET_FILTERS";

export const ADD_RECIPE = "ADD_RECIPE";
export const ADD_RECIPE_COMPLETE = "ADD_RECIPE_COMPLETE";
export const ADD_RECIPE_FAILED = "ADD_RECIPE_FAILED";

export const EDIT_RECIPE = "EDIT_RECIPE";
export const EDIT_RECIPE_COMPLETE = "EDIT_RECIPE_COMPLETE";
export const EDIT_RECIPE_FAILED = "EDIT_RECIPE_FAILED";

export const DELETE_RECIPE = "DELETE_RECIPE";
export const DELETE_RECIPE_COMPLETE = "DELETE_RECIPE_COMPLETE";
export const DELETE_RECIPE_FAILED = "DELETE_RECIPE_FAILED";

export const SET_REGION = "SET_REGION";

export const LOCAL_HOST = 'http://192.168.1.51';
export const DEV_HOST = 'https://recipes.robertjkeller.com';
export const HOST = 'https://kellercreations.com';
// export const DEV_PORT = ':3002';
// export const PORT = ':3001';
export const LOCAL_PORT = '';
export const DEV_PORT = '';
export const PORT = '';

export const S3_HOST = 'https://kellercreations.s3.amazonaws.com';
export const S3_PORT = '';

export const REGIONS = {
    LOCAL: "local",
    DEV: "dev",
    PRD: "prd",
}

export const SortOptions = {
    NAME_ASC: 'Name - A to Z', // A-Z
    NAME_DES: 'Name - Z to A', // Z-A
    RATING_ASC: 'Rating - Low to High', // 0-5
    RATING_DES: 'Rating - High to Low' // 5-0
}

export const NoomRatings = {
    NONE: "None",
    GREEN: "Green",
    YELLOW: "Yellow",
    RED: "Red"
}