import { GET_RECIPES, GET_RECIPES_LOADED, GET_RECIPES_FAILED, SET_SORT_OPTION, EDIT_RECIPE, EDIT_RECIPE_COMPLETE, EDIT_RECIPE_FAILED, ADD_RECIPE, ADD_RECIPE_COMPLETE, ADD_RECIPE_FAILED, DELETE_RECIPE, DELETE_RECIPE_COMPLETE, DELETE_RECIPE_FAILED, HOST, DEV_HOST, LOCAL_HOST, DEV_PORT, PORT, LOCAL_PORT, SortOptions, SET_FILTERS } from '../setup/constants';
import { getBaseUrl } from '../setup/helpers';

import axios from 'axios';

export function getRecipes () {
    return {type: GET_RECIPES, loading: true};
}

export function getRecipesLoaded (recipes) {
    return {type: GET_RECIPES_LOADED, loading: false, recipes: recipes};
}

export function getRecipesFailed (error) {
    return {type: GET_RECIPES_FAILED, loading: false, error: error};
}

export function fetchRecipes () {
  return async (dispatch, getState) => {
    dispatch(getRecipes());
    
    const recipesUrl = getBaseUrl(getState().DeviceReducer.region) + '/recipes.json';
    console.log(recipesUrl);

    axios
      .get(recipesUrl, {
        timeout: 15000
      })
      .then(res => {
        var recipes = {};

        res.data.Items.forEach(function (recipe) {
          recipes[recipe.id] = recipe;
        });

        dispatch(getRecipesLoaded(recipes));
      })
      .catch(err => {
        console.log("Fetch failed!!!", err);
        dispatch(getRecipesFailed(err.message));
      });
  };
}

export function saveAddRecipe (formData) {
  return async (dispatch, getState) => {
    dispatch(addRecipe());

    const recipesUrl = getBaseUrl(getState().DeviceReducer.region) + '/recipes.json';

    axios
      .post(recipesUrl, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000
        // onUploadProgress: callback,
      })
      .then((response) => {
        console.log(response);
        dispatch(addRecipeComplete(response.data));
      })
      .catch(err => {
        console.log(err);
        if (err.response === undefined) {
          dispatch(addRecipeFailed(err.message));  
        } else {
          console.log("save add failed!!!", err.response.data);
          dispatch(addRecipeFailed(err.response.data.message));
        }
      });
  };
}

export function addRecipe () {
  return {type: ADD_RECIPE, loading: true};
}

export function addRecipeComplete (recipe) {
  return {type: ADD_RECIPE_COMPLETE, loading: false, recipe: recipe};
}

export function addRecipeFailed (error) {
  return {type: ADD_RECIPE_FAILED, loading: false, error: error};
}

export function saveEditRecipe (formData, recipeId) {
  return async (dispatch, getState) => {
    dispatch(editRecipe());

    const recipesUrl = getBaseUrl(getState().DeviceReducer.region) + '/recipes/' + recipeId + '.json';
    
    axios
      .post(recipesUrl, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress: callback,
      })
      .then((response) => {
        dispatch(editRecipeComplete(response.data))
      })
      .catch(err => {
        console.log("save edit failed!!!", err);
        dispatch(editRecipeFailed(err.message));
      });
  };
}

export function editRecipe () {
  return {type: EDIT_RECIPE, loading: true};
}

export function editRecipeComplete (recipe) {
  return {type: EDIT_RECIPE_COMPLETE, loading: false, error: '', recipe: recipe};
}

export function editRecipeFailed (error) {
  return {type: EDIT_RECIPE_FAILED, loading: false, error: error};
}

export function deleteRecipePost (recipeId) {

  return async (dispatch, getState) => {
    dispatch(deleteRecipe());

    const recipesUrl = getBaseUrl(getState().DeviceReducer.region) + '/recipes/' + recipeId + '.json';

    axios
      .delete(recipesUrl)
      .then((response) => {
        dispatch(deleteRecipeComplete(response.data))
      })
      .catch(err => {
        console.log("save delete failed!!!", err);
        dispatch(deleteRecipeFailed(err.message));
      });
  };
}

export function deleteRecipe () {
  return {type: DELETE_RECIPE, loading: true};
}

export function deleteRecipeComplete (recipe) {
  return {type: DELETE_RECIPE_COMPLETE, loading: false};
}

export function deleteRecipeFailed (error) {
  return {type: DELETE_RECIPE_FAILED, loading: false, error: error};
}

export function setFilters (filters) {
  return async (dispatch) => {
    dispatch({
      type: SET_FILTERS,
      filters: filters
    });
  }
}

export function setSortOption (option) {
  return async (dispatch) => {
    dispatch({
      type: SET_SORT_OPTION,
      option: SortOptions[option]
    });
  } 
}