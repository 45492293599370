import { subStringInArray, exactStringInArray } from './helpers';
import { SortOptions } from './constants';

export function filterAndSortRecipes (recipes, filters, sortOption) {    
    return getSortedRecipes(getFilteredRecipes(recipes, filters), sortOption);
}

function getFilteredRecipes (recipes, filters) {

    var filteredRecipes = [];
    
    // TODO - Leverage an array and use the filter method for better performance, then make the same change to the sort functions.
    for (var key in recipes) {

        if (filters.name !== undefined && filters.name !== '') {
            let caseInsensitiveName = recipes[key].name.toLowerCase();
            let caseInsensitiveFilter = filters.name.toLowerCase();
            let result = caseInsensitiveFilter.split(' ').filter(word => caseInsensitiveName.includes(word))
            if (result.length === 0) {
                continue;
            }
        }
        if (filters.rating !== undefined && filters.rating !== '' && recipes[key].rating !== filters.rating) {
            continue;
        }
        if (filters.noomRating !== undefined && filters.noomRating !== 'None' && recipes[key].noomRating !== filters.noomRating) {
            continue;
        }
        if (filters.ingredients !== undefined && filters.ingredients.length > 0) {
            // No ingredients so skip the entry
            if (recipes[key].ingredients === undefined) {
                continue;
            }

            let mapResult = recipes[key].ingredients.map(recipeIngredientObject => {            
                return subStringInArray(recipeIngredientObject.ingredient, filters.ingredients);                
            });

            if (filters.allIngredients) {
                if (mapResult.filter(value => { return value}).length !== filters.ingredients.length) {
                    continue;
                }
            } else {
                if (!mapResult.reduce((acc, next) => acc || next)) {
                    continue;
                }
            }
        }
        if (filters.categories !== undefined && filters.categories.length > 0) {
            // No categories so skip the entry
            if (recipes[key].category === undefined || recipes[key].category.length === 0) {
                continue;
            }

            let mapResult = recipes[key].category.map(recipeCategory => {
                return exactStringInArray(recipeCategory, filters.categories);                        
            });

            if (filters.allCategories) {
                // Number of "true" values should match the number of categories entered or else skip them from the list
                if (mapResult.filter(value => { return value}).length !== filters.categories.length) {
                    continue;
                }
            } else {
                // Ensure there is at least one true or else skip them from the list
                if (!mapResult.reduce((acc, next) => acc || next)) {
                    continue;
                }
            }
            
        }

        filteredRecipes.push(recipes[key]);
    }

    return filteredRecipes;
}

function getSortedRecipes (recipes, sortOption) {

    switch (sortOption) {
        case SortOptions.NAME_ASC:
            return createNameAscendingSectionList(recipes);
        case SortOptions.NAME_DES:
            return createNameDescendingSectionList(recipes);
        case SortOptions.RATING_ASC:
            return createRatingAscendingSectionList(recipes);
        case SortOptions.RATING_DES:
            return createRatingDescendingSectionList(recipes);
        default:
            return recipes;
    }
}

function createRatingAscendingSectionList(recipes) {
    
    var sectionsArrayList = {};
    var sections = [];
    
    for (let key in recipes) {
      var rating = recipes[key].rating;
      if (!sectionsArrayList[rating]) {
        sectionsArrayList[rating] = [];
      }
      sectionsArrayList[rating].push(recipes[key]);
    };

    for (let key in sectionsArrayList) {
      sections.push({
        key: key,
        data: sectionsArrayList[key].sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      });
    }

    sections.sort(function (a,b) {
        return (a.key < b.key) ? -1 : (a.key > b.key) ? 1 : 0
    });

    return sections;
}

function createRatingDescendingSectionList(recipes) {
    
    var sectionsArrayList = {};
    var sections = [];
    
    for (let key in recipes) {
      var rating = recipes[key].rating;
      if (!sectionsArrayList[rating]) {
        sectionsArrayList[rating] = [];
      }
      sectionsArrayList[rating].push(recipes[key]);
    };

    for (let key in sectionsArrayList) {
      sections.push({
        key: key,
        data: sectionsArrayList[key].sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      });
    }

    sections.sort(function (a,b) {
        return (a.key > b.key) ? -1 : (a.key > b.key) ? 1 : 0
    });

    return sections;
}

function createNameAscendingSectionList (recipes) {

    var sectionsArrayList = {};
    var sections = [];
    
    for (let key in recipes) {
      var firstChar = recipes[key].name.charAt(0).toUpperCase();
      if (!sectionsArrayList[firstChar]) {
        sectionsArrayList[firstChar] = [];
      }
      sectionsArrayList[firstChar].push(recipes[key]);
    };

    for (let key in sectionsArrayList) {
      sections.push({
        key: key,
        data: sectionsArrayList[key].sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      });
    }

    sections.sort(function (a,b) {
        return (a.key < b.key) ? -1 : (a.key > b.key) ? 1 : 0
    });

    return sections;
}

function createNameDescendingSectionList (recipes) {

    var sectionsArrayList = {};
    var sections = [];
    
    for (let key in recipes) {
      var firstChar = recipes[key].name.charAt(0).toUpperCase();
      if (!sectionsArrayList[firstChar]) {
        sectionsArrayList[firstChar] = [];
      }
      sectionsArrayList[firstChar].push(recipes[key]);
    };

    for (let key in sectionsArrayList) {
      sections.push({
        key: key,
        data: sectionsArrayList[key].sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      });
    }
     
    sections.sort(function (a,b) {
        return (a.key > b.key) ? -1 : (a.key < b.key) ? 1 : 0
    });

    return sections;
}