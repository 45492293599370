import React from 'react';
import { Container, Row, Col, Image, Button, Jumbotron} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './style.css';

class Home extends React.Component {

    render() {

        return (
            <Container className="pitch">
                <Jumbotron>
                    <Row>
                        <Col className="my-auto">
                            <h2>Recipes made easy!</h2>
                            <h5>This is a lot of text that will go here</h5>
                            <Button as={Link} to="/login">Log In</Button>
                        </Col>
                        <Col>
                            <Image thumbnail fluid src="https://images.pexels.com/photos/372882/pexels-photo-372882.jpeg?cs=srgb&dl=pexels-pixabay-372882.jpg&fm=jpg" />
                        </Col>
                    </Row>                
                </Jumbotron>
            </Container>        
        );
    }
}

export default Home;