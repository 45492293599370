import logo from './logo.svg';
import './App.css';
import Recipe from './pages/recipe';
import Recipes from './pages/recipes';
import Home from './pages/home';
import Login from './pages/login';
import { Button, Navbar } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar bg="primary" variant="dark" expand="sm" fixed="top">
          <Navbar.Brand as={Link} to="/">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            {' '} Recipes
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Button as={Link} to="/login" variant="secondary">Log in</Button>
          </Navbar.Collapse>
        </Navbar> 

        <Switch>          
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/recipes">
            <Recipes />
          </Route>
          <Route path="/recipes/:id" component={Recipe}/>
          <Route path="/">
            <Home />
          </Route>
        </Switch>          
      </div>
    </Router>
  );
}

export default App;
