import { createReducer } from '@reduxjs/toolkit';
import { SET_REGION } from '../setup/constants'

const INITIAL_STATE = {
  deviceType: '',
  region: 'prd'
};

const DeviceReducer = createReducer(INITIAL_STATE, {
  SET_REGION: (state, action) => {
    state.region = action.region;
  }   
});

export default DeviceReducer;