import { REGIONS, LOCAL_HOST, LOCAL_PORT, DEV_HOST, DEV_PORT, HOST, PORT } from "./constants";

export function getBaseUrl (region) {
    if (region === REGIONS.LOCAL) {
      return LOCAL_HOST + LOCAL_PORT;
    } else if (region === REGIONS.DEV) {
      return DEV_HOST + DEV_PORT;
    } else {
      return HOST + PORT;
    }
}

export function exactStringInArray (str, arr, caseInsensitive = true) {
    if (arr === undefined || arr.length === 0) {
        return false;
    }

    let searchStr = (caseInsensitive) ? str.toLowerCase() : str;
    let searchArr = (caseInsensitive)? arr.map((elem) => elem.toLowerCase()) : arr;

    return searchArr.indexOf(searchStr) >= 0;
}

export function subStringInArray (str, arr, caseInsensitive = true) {
    if (arr === undefined || arr.length === 0) {
        return false;
    }

    let searchStr = (caseInsensitive) ? str.toLowerCase() : str;
    let searchResult = arr.filter((elem) => {
        let searchElem = (caseInsensitive) ? elem.toLowerCase() : elem;
        let elemContains = searchElem.includes(searchStr);
        let searchContains = searchStr.includes(searchElem);
        return elemContains || searchContains;
    });

    return searchResult.length > 0;
}